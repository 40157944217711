import React, { Component } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { TextArea } from "@instructure/ui-forms";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import QuestionHeader from "../../../components/question-header";
import styles from "./what-is-normalcy.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import { func } from "prop-types";

export class WhatIsNormalcy extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false
  };
  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(2);
  }

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({
        hasSubmitted: true
      });
    } else {
      this.props.goToStep(3, "/modules/normalcy/normalcy-law");
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted) {
      this.setState({
        hasSubmitted: false
      });
    } else {
      this.props.goToStep(1, "/modules/normalcy/");
    }
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - What is Normalcy"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.informationTextArea}>
            An important idea in caring for children in care is "normalcy."
          </div>
          <div className={styles.responseTextArea}>
            <TextArea
              resize
              label={
                <QuestionHeader
                  icon={
                    <span
                      className={styles.emoji}
                      role="img"
                      aria-label="Thinking Face"
                    >
                      🤔
                    </span>
                  }
                >
                  What do you think "normalcy" means?
                </QuestionHeader>
              }
            />
            {this.state.hasSubmitted && (
              <div className={styles.responseFeedbackArea}>
                <>
                  <div className={styles.feedbackBlurb}>
                    Normalcy is the need for children in foster care to
                    participate, without undue obstacles, in activities that are
                    part of a normal, healthy childhood.
                  </div>
                  <p>
                    Because this need is so important, Utah has created a{" "}
                    <b>normalcy law</b> to give children in care a better chance
                    at a healthy childhood.
                  </p>
                </>
              </div>
            )}
          </div>

          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(WhatIsNormalcy);
